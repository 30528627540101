import EditableUserFactory from '../domain/services/EditableUserFactory'
import { HTTP } from '../services/http'

const URL_GET_EMPLOYEES = 'api/users/me/business/employees'
const URL_GET_EMPLOYEE_BY_ID = 'api/users/me/business/employees/{id}'
const URL_CREATE_EMPLOYEE = 'api/users/me/business/employees'
const URL_UPDATE_EMPLOYEE = 'api/users/me/business/employees'
const URL_DELETE_EMPLOYEE = 'api/users/me/business/employees/{id}'

const headers = {
  'Content-Type': 'application/json'
}

export function getEmployeesApi () {
  return HTTP.get(URL_GET_EMPLOYEES)
    .then(response => response.data.collection.map(employee => EditableUserFactory.getEditableUser(employee)))
}

export function getEmployeeByIdApi (id) {
  const url = URL_GET_EMPLOYEE_BY_ID.replace('{id}', id)
  return HTTP.get(url)
    .then(response => EditableUserFactory.getEditableUser(response.data))
}

export function addEmployeeApi (data) {
  delete data.repeatedPassword
  return HTTP.post(URL_CREATE_EMPLOYEE, data, { headers })
    .then(response => EditableUserFactory.getEditableUser(response.data))
}

export function updateEmployeeApi (data) {
  return HTTP.put(URL_UPDATE_EMPLOYEE, data, { headers })
    .then(response => EditableUserFactory.getEditableUser(response.data))
}

export function deleteEmployeeApi (id) {
  const url = URL_DELETE_EMPLOYEE.replace('{id}', id)
  return HTTP.delete(url)
    .then(response => response)
}
