<template>
  <div>
    <EmployeeLayout v-if="true">
      <b-card
        :title="'Editar Personal'"
        class="user-create-card"
        align="left"
      >
        <EmployeeForm
          v-if="!!this.form"
          ref="employeeForm"
          :form_prop.sync="form"
        />
        <b-row class="footer">
          <b-col>
            <b-button variant="secondary" @click="onDiscard" class="d-flex">
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-col>
          <b-col class="footer-buttons">
            <b-button variant="primary" @click="onSave()">
              Guardar cambios
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </EmployeeLayout>
    <RemoveConfirmationModal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmDiscard"
      @onCancelRemoveConfirmation="onCancelDiscard"
    />
  </div>
</template>

<script>
import EmployeeLayout from '../layout/EmployeeLayout.vue'
import { BIconTrash } from 'bootstrap-vue'
import EmployeeForm from '@/components/Employee/EmployeeForm/EmployeeForm.vue'
import { getEmployeeByIdApi } from '../../../api/AvifyEmployeeApi'
import RemoveConfirmationModal from '@/components/Modal/RemoveConfirmationModal'

export default {
  name: 'EmployeeEdit',
  components: {
    RemoveConfirmationModal,
    EmployeeLayout,
    BIconTrash,
    EmployeeForm
  },
  data () {
    return {
      form: null
    }
  },
  async created () {
    this.lockScreen()
    await getEmployeeByIdApi(this.$route.params.id).then(response => {
      this.form = response
    })
    this.unlockScreen()
  },
  computed: {
    confirmationModalId () {
      return 'confirmation-discard-modal' + this.$store.getters['employee/selected']?.id
    }
  },
  methods: {
    async onSave () {
      const isEmployeeValid = await this.$refs.employeeForm.$refs.employeeForm.validate()
      if (!isEmployeeValid) {
        return
      }
      this.lockScreen()
      delete this.form.email
      delete this.form.technician.id
      this.$store.dispatch('employee/update', this.form)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'employee-list' })
        })
    },
    onDiscard () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onCancelDiscard () {
      this.$bvModal.hide(this.confirmationModalId)
    },
    onConfirmDiscard () {
      this.lockScreen()
      this.$store.dispatch('employee/delete', this.form.id)
        .then(() => {
          this.onCancelDiscard()
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'employee-list' })
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "employeeEdit";
</style>
